import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Card, MobileNav, Navbar,IconButton, Collapse, Typography } from "@material-tailwind/react";


const Nav = () => {

    const [openNav, setOpenNav] = useState(false);

    const navList = (
        <ul className="flex flex-col gap-2 mt-2 md:flex-row md:mt-0 md:mx-auto md:mb-0 md:items-center md:gap-6">
            <Link to="/" className="navLink md:p-5 p-1 w-1/2 md:w-auto cursor-pointer" onClick={() => setOpenNav(false)}>
                <Typography as="li" variant="small" className="p-1 md:text-3xl">Home</Typography>
            </Link>
            <Link to="/services" className="navLink md:p-5 p-1 w-1/2 md:w-auto cursor-pointer" onClick={() => setOpenNav(false)} >
            <Typography as="li" variant="small" className="p-1 md:text-3xl">Services</Typography>
            </Link>
            <Link to="/projects" className="navLink md:p-5 p-1 w-1/2 md:w-auto cursor-pointer" onClick={() => setOpenNav(false)}>
            <Typography as="li" variant="small" className="p-1 md:text-3xl">Projects</Typography>
            </Link>
            <Link to="/contact" className="navLink md:p-5 p-1 w-1/2 md:w-auto cursor-pointer" onClick={() => setOpenNav(false)} >
            <Typography as="li" variant="small" className="p-1 md:text-3xl">Contact</Typography>
            </Link>
        </ul>
    )

    return (
        <div id="navContainer" className=" md:text-4xl  p-1 bg-black text-white top-0 fixed w-full z-20 items-center sticky inset-0 h-max max-w-full">
            <div className="container flex justify-between items-center mx-auto w-full">
            
                <Link to="/" id="navTitle" className="py-1 cursor-pointer" onClick={() => setOpenNav(false)}>
                <Typography className="cursor-pointer py-1 md:text-3xl" variant="h6"> Interior Designer Services, Inc. </Typography>
                    </Link>
            
            <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
            >
            {openNav ? (
                <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                />
                </svg>
            ) : (
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                />
                </svg>
            )}
            </IconButton>
                <div id="navLinksContainer" className="flex flex-wrap align-center justify-center hidden md:flex">
                    {navList}
                </div>
            </div>
            <Collapse open={openNav}>
            {navList}
        </Collapse>
        </div>
    )
}

export default Nav;