import { useState } from "react";
import background from "../iso-republic-textured-grey-fabric copy.jpg"
import { useNavigate } from "react-router";
import { useEffect } from "react";

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    // async function submit () {
    //     console.log(name + " " + email + " " + subject +" " + message)
    //     alert("Your message has been sent. Please allow three business days for a response")
    //     navigate("/")
    // }

    return (
    <div className="flex flex-col-reverse md:flex-row justify-around p-5 md:mt-20 md:h-auto mt-10 bg-cover bg-center h-screen bg-origin-border	bg-fixed items-center" style={{backgroundImage: `url(${background})`}}>
        <div className=" flex flex-col justify-center text-center md:w-1/3 animation bg-black text-white rounded p-3">
            <p className="text-3xl">Get in touch</p>
            <form className="p-3 flex flex-col justify-center" action="mailto:james@interior-designer-svc.com" method="get" encType="text/plain">
            {/* <input type="text" placeholder="Name" name="subject" className="p-3 m-2 text-black" onChange={(event => setName(event.target.value))}></input> */}
            <input type="text" placeholder="Email" name="email" className="p-3 m-2 text-black" onChange={(event => setEmail(event.target.value))}></input>
            <input type="text" placeholder="Subject"  name="subject" className="p-3 m-2 text-black" onChange={(event => setSubject(event.target.value))}></input>
            <textarea placeholder="Message" className="p-3 m-2 text-black" name="body" onChange={(event => setMessage(event.target.value))}></textarea>
            <input type="submit" className="bg-black text-white rounded p-3 w-1/2 self-center cursor-pointer" value="Send" ></input>
            </form>
        </div>
        <div className="flex flex-col justify-around align-center animation text-2xl" >
                <div className="bg-black text-white rounded p-5 m-5">
                    <p>Call:</p>
                    <p>(770)-921-1611</p>
                </div>
                <a className="bg-black text-white rounded p-5 m-5" href="mailto:james@interior-designer-svc.com">
                    <p>Email:</p>
                    <p>james@interior-designer-svc.com</p>
                </a>
        </div>
    </div>)
}

export default Contact;


// // onClick={(event) => {
//     event.preventDefault();
//     if(!name.length){
//         event.target.classList.add("errorMessage")
//         event.target.value = "Enter a name";
//     setTimeout(()=> {
//         event.target.classList.remove("errorMessage");
//         event.target.value="Send";
//     }, 500)
//     }else if(!email.length || !email.includes("@")){
//         event.target.classList.add("errorMessage")
//         event.target.value = "Enter a valid email";
//         setTimeout(()=>{
//             event.target.value="Send";
//             event.target.classList.remove("errorMessage");
//     }, 500)
//     }else if(!subject.length){
//         event.target.classList.add("errorMessage")
//         event.target.value = "Enter a subject";
//         setTimeout(()=>{
//             event.target.value="Send";
//             event.target.classList.remove("errorMessage");
//     }, 500)
//     }else if(!message.length){
//         event.target.classList.add("errorMessage")
//         event.target.value = "Enter a message";
//         setTimeout(()=>{
//             event.target.value="Send";
//             event.target.classList.remove("errorMessage");
//     }, 500)}
//      else{
//         submit()
//     }
//     }}