import background from "../iso-republic-textured-grey-fabric copy.jpg"
import serviceImage from "../services copy.jpg"
import { useEffect } from "react"


const Services = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
    <div className=" bg-cover bg-center h-200 bg-origin-border	bg-fixed flex flex-col items-center m:0 md:mt-15 pt-5 overflow-hidden md:mb-10" style={{backgroundImage: `url(${background})`}}>
        <div className="animation w-screen flex justify-center ">
            <img src={serviceImage}/>
           
        </div>
        <div className="flex justify-around text-center text-xl flex-wrap animation pb-5">
            <section className="md:w-2/5 bg-black text-white rounded p-5 m-5">
                <p className="text-3xl">Custom Quilting</p>
                <p>One of our specialties, our custom quilting takes great care with each design. Whether we’re outlining the print of a fabric or using one of our own designs, our finishing details make each quilting project unique.<br/> <br/> We can also quilt yardage for your upholstery needs.</p>
            </section>
            <section className="md:w-2/5 bg-black text-white rounded p-5 m-5">
                <p className="text-3xl">Drapery Panels</p>
                <p>One of our our most requested items, we create to the specific widths and lengths necessary for your finished design. <br/> <br/>Two story windows are no problem! We also offer a large array of drapery heading styles.</p>
            </section>
            <section className="md:w-2/5 bg-black text-white rounded p-5 m-5">
                <p className="text-3xl">Valances & Cornice Boards</p>
                <p>Add elegance and luxury to your windows with creative valances or cornice boards. <br/> <br/> Frames are built in-house to exacting tolerances to ensure your design flows perfectly with the other items that make up your decor.</p>
            </section>
            <section className="md:w-2/5 bg-black text-white rounded p-5 m-5">
                <p className="text-3xl">Pillows</p>
                <p>Custom made pillows add a finishing touch to any design project. <br/> <br/>  We can make in any size or shape with or without cording and with a multitude of inserts.</p>
            </section>
        </div>
    </div>)
}
export default Services;