import background from "../iso-republic-textured-grey-fabric copy.jpg"
import homeImage from "../home_bedroom-pillows-bedding copy.jpg"
import { useEffect } from "react"

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="homeContainer" className="flex flex-col items-center pt-5 md:mt-15 bg-cover bg-center bg-origin-border m:0	bg-fixed overflow-hidden md:mb-10" style={{ backgroundImage: `url(${background})` }}>
            <div id="imageContainer" className="animation w-screen flex justify-center">
                <img className="" src={homeImage} />
            </div>
            <div id="tagLineContainer" className="m-3 bg-black text-white rounded p-5 animation">
                <p id="tagLine" className="text-6xl text-center">Crafting Art from Fabrics</p>
            </div>
            <div id="contentContainer" className="flex flex-col p-10 justify-around text-center text-xl animation md:grid md:grid-cols-3 md:gap-2">
                <a href="/projects" >
                    <section id="whoContainer" className="homeContent   bg-black text-white rounded p-5 m-1 md:m-0">
                        <p id="whoTitle" className="contentTitle text-3xl">Who We Are!</p>
                        <p id="whoContent" className="contentText">Interior Designer Services is a team of seasoned seamstresses crafting fabrics into beautiful and functional art.</p>
                    </section>
                </a>
                <a href="/services">
                    <section id="whatContainer" className="homeContent  bg-black text-white rounded p-5 m-1 md:m-0">
                        <p id="whatTitle" className="contentTitle text-3xl">What We Do!</p>
                        <p id="whatContent" className="contentText">From draperies to custom quilting, unique headboard and custom cornice design to excellent embroidery services, Interior Designer Services can help you create the stunning fabric design and effect that will finish your rooms off with a sense of style.</p>
                    </section>
                </a>
                <a href="/contact" >
                    <section id="touchContainer" className="homeContent bg-black text-white rounded p-5 m-1 md:m-0">
                        <p id="touchTitle" className="contentTitle text-3xl">Get In Touch!</p>
                        <p id="touchContent" className="contentText">Your custom designs come to life at Interior Designer Services.We love helping our clients add the finishing decorative touches that makes houses homes and help business stand out! We’d love to discuss your latest project, so don’t hesitate to get in touch so we can start designing!</p>
                    </section>
                </a>
            </div>
        </div>
    )
}

export default Home;