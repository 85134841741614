import background from "../iso-republic-textured-grey-fabric copy.jpg"

const Admin = () => {
    return (
        <div className="mt-30 bg-cover bg-center h-screen bg-origin-border	bg-fixed flex flex-col items-center m:0 md:mt-20 pt-5 overflow-hidden" style={{backgroundImage: `url(${background})`}}>
            <form netlify name="admin" method="POST">
            <input className="m-10" type="file"></input>
            <input type="submit" value="Submit" className="bg-black text-white rounded p-3 w-1/2 self-center cursor-pointer"></input>
            <input type="hidden" name="form-name" value="admin" />
            </form>
        </div>
    )
}

export default Admin