import {createRoot} from "react-dom/client";
import {BrowserRouter, Link, Routes, Route} from "react-router-dom"
import { Home, Nav, Services, Projects, Contact, Footer, Admin } from "./components";
import { useEffect } from "react";

import "./index.css"


const App = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    return (
        <BrowserRouter >
            <Nav />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/projects" element = {<Projects />} />
                <Route path="/contact" element = {<Contact />} />
                <Route path="/admin" element = {<Admin />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}
const appElt = document.getElementById("app")
const root = createRoot(appElt)
root.render(<App />)